<template>
  <div id="harvest" v-bind:class="{ fullscreen: screensize }">
    <b-toast id="harvestWiz_error" class="harvest__toast" variant="danger" title="Harvest Wizard Error" :no-auto-hide="noAutoHide" static>
      {{ errMsg }}
    </b-toast>
    <b-toast id="harvestWiz_success" variant="primary" title="Harvest Wizard Success">
				{{ sucMsg }}
			</b-toast>

    <div class="harvest__card box-shadow-1 scroll">
      <div class="harvest__header">
        <h3>{{ step | headerTitle }}</h3>
        <div class="harvest__header__btn">
          <b-button @click="toggleScreen()" class="headerBtn" variant="secondary">
            <i v-show="!screensize" class="fas fa-expand"></i>
            <i v-show="screensize" class="fas fa-compress"></i>
          </b-button>
          <b-button v-b-modal.modal-confirmEnd class="headerBtn" v-show="step == 1" variant="danger">
            <span><i class="fas fa-times"></i></span>
          </b-button>
        </div>
      </div>

      <div class="harvest__body scroll scroll-white" v-if="dataReady">
        <div class="harvest__body__selection" v-show="step == 0">
          <ul class="harveststation-list">
            <li
              v-for="(item, index) in harveststations"
              :key="index"
              class="harveststation-card"
              :class="item.status"
              v-on:click="selectedHS(item)"
            >
              <div class="harveststation-card__content">
                <h4>{{ item.name }}</h4>
              </div>
              <div class="harveststation-card__tags">
                <div class="harveststation-card__tags__icons">
                  <span>
                    <i class="fas fa-wifi" v-show="item.status == 'online'"></i>
                    <i class="fas fa-link" v-show="item.status == 'busy'"></i>
                    <i class="fas fa-unlink" v-show="item.status == 'offline'"></i>
                  </span>
                  <h5>{{ item.status }}</h5>
                </div>
                <h5 class="harvest__card-tag__selected" v-show="item.name == hsId">
                  SELECTED
                </h5>
              </div>
            </li>
          </ul>
        </div>

        <div class="harvest__ops" v-show="step == 1">
          <div class="harvest__ops__ctrl">
            <div class="massBox">
              <h1 style="font-family: 'courier new', 'monospace';">{{ mass }} g</h1>
            </div>
            <div class="harvest__ops__ctrl__btn">
              <b-button variant="danger" :disabled="token == null" v-on:click="tareScale">
                TARE
              </b-button>
              <b-button variant="primary" :disabled="token == null" v-on:click="openSubmissionModal">
                <i class="fas fa-step-forward"></i>
              </b-button>
            </div>
          </div>
          
          <div class="harvest__ops__details">
            <ul class="harvest__ops__details__info">
              <li v-if="farm == 'fxothers'" style="background: none">
                <b-button variant="secondary" v-b-modal.modal-addzone>+</b-button>
              </li>
              <li style="background: none">
                <b-button variant="warning" v-b-modal.modal-config><i class="fas fa-cog"></i></b-button>
              </li>
              <li v-if="hsId">
                {{ hsId }}
              </li>
              <li v-if="zone">
                {{ zone }}
              </li>
              <li v-else class="empty-selection">No Zone</li>
              <li v-if="growarea">
                  {{ growarea }}
              </li>
              <li v-else class="empty-selection">No GrowArea</li>
              <li v-if="variant" class="">
                {{ variant }}
              </li>
              <li v-else class="empty-selection">No Variant</li>
              <li>{{ harvestConfig.massmod.label || "..." }}</li>
              <li v-if="batch">{{ totalAgg.harvest.qty }} of {{ batch.quantity }}</li>
            </ul>

            <div v-if="rawHarvestData.length > 0" class="harvestData_list scroll">
              <b-tabs>
                <b-tab title="Active">
                  <div v-for="(hData, hid) in harvestData" :key="hid">
                    <HarvestDataCard :farm="farm" :harvest="hData" 
                    @success="showSuccessToast" @error="showErrorToast" :disabled="token == null" 
                    />
                  </div>
                  <div class="harvestData_card box-shadow-1">
                    <div class="harvestData_card__info-col">
                      <h4>TOTAL</h4>
                      <h5>{{ totalAgg.harvest.qty | qtyDisp }}</h5>
                      <h5>{{ totalAgg.harvest.mass }} g</h5>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Archived">
                  <div v-for="(ahData, ahid) in archivedHarvestData" :key="ahid">
                    <HarvestDataCard class="archived" :farm="farm" :harvest="ahData"
                      @success="showSuccessToast" @error="showErrorToast" :disabled="token == null"
                    />
                  </div>
                  <div class="harvestData_card box-shadow-1 archived">
                    <div class="harvestData_card__info-col">
                      <h4>TOTAL</h4>
                      <h5>{{ totalAgg.archived.qty | qtyDisp }}</h5>
                      <h5>{{ totalAgg.archived.mass }} g</h5>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>  
            </div>
            <div v-else>
              <p>submit a harvest data point for it to show up here</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="pulse p-2 m-4 bg-secondary color-secondary">
        ...
      </div>
    </div>

    <b-modal id="modal-harvest" title="Specify Quantity" :hide-footer="true" v-if="step == 1">
      <div style="user-select: none">
        <div class="harvest__ops__details">
          <ul class="harvest__ops__details__info">
            <li>{{ hsId }}</li>
            <li v-if="zone">
                {{ zone }}
            </li>
            <li v-else class="empty-selection">No Zone</li>
            <li v-if="growarea">
                {{ growarea }}
            </li>
            <li v-else class="empty-selection">No GrowArea</li>
            <li v-if="variant" class="">
              {{ variant }}
            </li>
            <li v-else class="empty-selection">No Variant</li>
            <li>{{ harvestConfig.massmod.label }}</li>
          </ul>
        </div>

        <div class="modal-harvest__info">
          <h3>{{ mass }} g</h3>
          <h3 style="text-align: right;">{{ qty | qtyDisp }}</h3>
        </div>

        <div class="harvest__numpad">
          <div v-for="n in btnArr" :key="n" @click="btnClickHandle(n)"> {{ n }} </div>
          <div class="harvest__numpad__actions"></div>
          <b-button type="submit" variant="danger" class="trigger" v-on:click="triggerHS(0)"> REJECT </b-button>
          <b-button type="submit" variant="primary" class="trigger" v-on:click="triggerHS(1)"> ACCEPT </b-button>
        </div>
      </div>
    </b-modal>

    <HarvestConfirmEndModal @ok="reset()" />
    <HarvestConfigModal :farm="farm" :updateZones="upZones" :batch="batch" @error="showErrorToast" @update="updateHarvestConfig" />
    <HarvestEditModal :farm="farm" @success="showSuccessToast" @error="showErrorToast" />
    <HarvestAddGAModal :farm="farm" @error="showErrorToast" @updateZones="upZones+=1" />
  </div>
</template>

<script>
import mqtt from "mqtt";
import axios from "axios";
import store from "../store";
import router from "../router";
import HarvestDataCard from "../components/harvestWiz/HarvestDataCard.vue";
import HarvestConfigModal from "../components/harvestWiz/HarvestConfigModal.vue";
import HarvestAddGAModal from "../components/harvestWiz/HarvestAddGAModal.vue";
import HarvestConfirmEndModal from "../components/harvestWiz/HarvestConfirmEndModal.vue";
import HarvestEditModal from "../components/harvestWiz/HarvestEditModal.vue";

export default {
  props: ["farm", "batchId"],
  components: { 
    HarvestDataCard,
    HarvestConfigModal,
    HarvestEditModal,
    HarvestAddGAModal,
    HarvestConfirmEndModal
  },
  data() {
    return {
      dataReady: false,
      timer: null,
      dataTimeout: null,
      step: 0,
      screensize: 0, //0 is regular view 1 is full screen;
      harvestConfig: { 
        tags: [],
        massmod: {name: "none", value: 0, label: "none (0g)"}
      },
      totalAgg: {
        harvest: {qty: 0, mass: -1},
        archived: {qty: 0, mass: -1}
      },
      harveststations: [],
      mass: -1,
      mass_lastUpdate: 0,
      user: localStorage.getItem("user"),
      rawHarvestData: [],
      harvestData: [],
      archivedHarvestData: [],
      client: null,
      btnArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, "DEL", 0, "RESET"],
      qty: 1,
      errMsg: "",
      sucMsg: "",
      noAutoHide: false,
      upZones: 0
    };
  },
  watch: {
    farm: function(){
      this.getHarvestStations();
    },
    rawHarvestData: function(){
      let hQtySum = this.harvestData.reduce((sum, d) => { 
        return sum + (d.status=="done" ? d.quantity : 0 );
      }, 0);
      let hMassSum = this.harvestData.reduce((sum, d) => { 
        return sum + (d.status=="done" ? d.mass : 0 );
      }, 0);

      let ahQtySum = this.archivedHarvestData.reduce((sum, d) => { 
        return sum + (d.status=="done" ? d.quantity : 0 );
      }, 0);
      let ahMassSum = this.archivedHarvestData.reduce((sum, d) => { 
        return sum + (d.status=="done" ? d.mass : 0 );
      }, 0);

      this.totalAgg = {harvest: {qty: hQtySum, mass: hMassSum}, archived: {qty: ahQtySum, mass: ahMassSum}};
    }
  },
  computed: {
    batch: {get(){ return store.state.batch; }, set(v){ store.commit('setBatchforHarvest', v); }},
    zone: {get(){ return store.state.zone }, set(v){ store.commit('setZone', v) }},
    growarea: {get(){ return store.state.growarea }, set(v){ store.commit('setGrowarea', v) }},
    variant: {get(){ return store.state.variant }, set(v){ store.commit('setVariant', v) }},
    hsId: {get(){ return store.state.hsId }, set(v){ store.commit('setHSID', v) }},
    token: {get(){ return store.state.token }, set(v){ store.commit('setToken', v) }},
    expiry: {get(){ return store.state.expiry }, set(v){ store.commit('setExpiry', v) }},
  },
  filters: {
    headerTitle: function(value) {
      switch (value) {
        case 0:
          return "Select Harvest Station";
        case 1:
          return "Perform Measurements";
        default:
          return 0;
      }
    },
    qtyDisp: function(value){
      return `${value} ${value > 1 ? 'units' : 'unit'}`;
    },
    variantDisp: function(value, variants) {
      const v = variants.find((v) => {
        return v._id == value;
      });
      return `${v.name} - ${v.lot}`;
    },
  },
  mounted() {
    this.mqttConnect();
    const cur_dt = new Date();
    const exp_dt = new Date(this.expiry);
    if(cur_dt > exp_dt){
      this.token = null;
      this.resetHarvestConfig();
      console.log("Token has expired");
    }
    if(this.batchId != null && this.batch == null){ this.getBatch(this.batchId); }

    this.timer = setInterval(() => {
      for (var st in this.harveststations) {
        const msg = JSON.stringify({ query: "isIdle" });
        this.mqttPublish(`/${this.harveststations[st].name}/query/request`, msg);
      }
    }, 15000)
  },
  beforeDestroy() {
    // if (this.session["token"]) {
    //   const msg = JSON.stringify({
    //     query: "endSession",
    //     user: this.session["user"],
    //     token: this.session["token"],
    //   });
    //   this.harvestConfig.hsId = null;
    //   localStorage.removeItem('hsId');
    //   this.mqttPublish(`/${this.harvestConfig.hsId}/query/request`, msg);
    // }
    this.client.end();
    clearInterval(this.timer);
  },
  methods: {
    mqttConnect() {
      let broker_url = process.env.VUE_APP_MQTTBROKER_URL || "mqtt://localhost:8883";
      if(process.env.VUE_APP_USE_NGINX){
        if (window.location.protocol === 'https:'){
          broker_url = 'wss://' + window.location.host + '/mqtt';
        }
        else if(window.location.protocol === 'http:'){
          broker_url = 'ws://' + window.location.host + '/mqtt';
        }
      }
      console.log(broker_url);
      this.client = mqtt.connect(broker_url, {
        username: process.env.VUE_APP_MQTTBROKER_USER,
        password: process.env.VUE_APP_MQTTBROKER_PASS,
      });

      this.client.on("connect", () => {
        console.log("MQTT: Connection succeeded!");
        this.getHarvestStations();
      });

      this.client.on("error", (error) => {
        console.log("MQTT: Connection failed", error);
      });

      // Topics follow convention {HS_ID}/Pri/Sec
      // Secondary are either request or response
      this.client.on("message", (topic, message) => {
        // console.log(`Received message from topic ${topic}: ${message}`);
        const topic_params = topic.split("/");
        const topic_hsid = topic_params[1];
        const topic_pri = topic_params[2];
        // const topic_sec = topic_params[3];
        if (topic_pri == "data") {
          // Handle data from Harvest Station
          const json = JSON.parse(message);
          this.mass = json["mass"];
          this.mass_lastUpdate = Date.now();
        } else if (topic_pri == "query") {
          // Handle query response from Harvest Station
          const json = JSON.parse(message);
          if(json["endSession"] == true) {
            // Response to end session in Harvest Station
            if(json['token'] == this.token){
              this.showErrorToast("Session with harvest station ended", true);
              this.mqttUnsubscribe(`/${this.hsId}/data`);
              this.token = null;
              this.resetHarvestConfig();
              clearTimeout(this.dataTimeout);
            } else {
              console.log("Harvest Station: token mismatch");
            }
          }
          else if(json["isIdle"] == true || json["isIdle"] == false){
            // Response to checking status of Harvest Station
            const idx = this.harveststations.findIndex(
              (hs) => hs.name == topic_hsid
            );
            this.$nextTick(() => {
              this.dataReady = false;
              if (json["isIdle"]) {
                this.harveststations[idx].status = "online";
              } else {
                if (json["user"] == this.user) {
                  this.harveststations[idx].status = "reserved";
                  this.expiry = json['expiry'];
                } else {
                  this.harveststations[idx].status = "busy";
                }
              }
              this.dataReady = true;
            });
          } else if(Object.prototype.hasOwnProperty.call(json, "reserveSession")) {
            // Response to reserving session in Harvest Station
            if(json['reserveSession']['user'] == this.user){
              this.token = json['reserveSession']['token'];
              this.expiry = json['reserveSession']['expiry'];
              this.mqttSubscribe(`/${topic_hsid}/data`);
              this.step = 1;
              // Handle situation if HS stopped sending mass data
              this.dataTimeoute = setTimeout(() => {
                if (
                  Date.now() - this.mass_lastUpdate > 10000 &&
                  this.hsId == topic_hsid && this.mass_lastUpdate > 0
                ) {
                  this.showErrorToast("Session with harvest station ended", true);
                  this.token = null;
                  this.resetHarvestConfig();
                  console.log("ERROR: Token expired or harvest station went offline");
                }
              }, 10000);
              this.$emit('updatefarmSelect', true);
            } else {
              console.log("Harvest Station: user mismatch");
            }
          } else if(Object.prototype.hasOwnProperty.call(json, "trigger")) {
            // Handle trigger response from Harvest Station
            const json = JSON.parse(message);
            if(json['trigger']['token'] == this.token){
              this.harvestData.push({
                capId: json["trigger"]["cap_id"],
                variant: json["trigger"]["variant"],
                quantity: json["trigger"]["qty"],
                label: json["trigger"]["label"],
                status: "pending",
              });
            }
          } else if(Object.prototype.hasOwnProperty.call(json, "submission")) {
            // Handle trigger response from Harvest Station
            const json = JSON.parse(message);
            if(json['submission']['token'] == this.token){
              const res = json['submission'];
              if(res['status'] == 0){
                // Successful submission, plantbatch update can be tried but not committed
                console.log(`Submission ${res["cap_id"]} is successful`);
                // console.log(res);
                this.getHarvestData(res["cap_id"]);
              } else {
                // Unsuccessful submission
                console.log(`Submission ${res["cap_id"]} is unsuccessful`);
                const sub = this.harvestData.find((s) => {
                  return s.capId == res["cap_id"];
                });
                sub["status"] = "failed";
              }
            }
          }
        }
      });
    },
    mqttPublish(topic, msg) {
      console.log("MQTT PUB: ", msg);
      this.client.publish(topic, msg, 2, (error) => {
        if (error) { console.log("MQTT ERROR: ", error); }
      });
    },
    mqttSubscribe(topic) {
      this.client.subscribe(topic, 2, (error, res) => {
        if (error) { console.log("MQTT ERROR: ", error); }
        console.log("MQTT SUB: ", res);
      });
    },
    mqttUnsubscribe(topic) {
      this.client.unsubscribe(topic, (error) => {
        if (error) { console.log("MQTT ERROR: ", error); }
      });
    },
    selectedHS(val) {
      if (val.status == "online") {
        this.hsId = val.name;
        const msg = JSON.stringify({
          query: "reserveSession",
          user: this.user,
        });
        this.mqttPublish(`/${this.hsId}/query/request`, msg);
      }
      else if (val.status == "reserved"){
        this.hsId = val.name;
        const cur_dt = new Date();
        const exp_dt = new Date(this.expiry);
        if(cur_dt < exp_dt){
          this.getHarvestData(-1);
          this.mqttSubscribe(`/${this.hsId}/data`);
          this.step = 1;
        }
        else {
          console.log("Token has expired");
        }
      }
      else if (val.status == "busy") {
        this.showErrorToast("Station is busy, please select a different station");
      } else {
        this.showErrorToast("Station is offline, please select a different station");
      }
    },
    updateHarvestConfig(hcfg){
      this.zone = hcfg.zone;
      this.growarea = hcfg.growarea;
      this.variant = hcfg.variant;
      this.harvestConfig.massmod = hcfg.massmod;
    },
    getHarvestStations() {
      axios.get(`/${this.farm}/harveststations`, { })
      .then((response) => {
        this.harveststations = response.data;
        for (var st in this.harveststations) {
          // Query status of Harvest Station over MQTT
          this.harveststations[st].status = "offline";
          const msg = JSON.stringify({ query: "isIdle" });
          this.mqttSubscribe(`/${this.harveststations[st].name}/query/response`);
          this.mqttPublish(`/${this.harveststations[st].name}/query/request`, msg);
        }
        this.$nextTick(() => { this.dataReady = true; });
      })
      .catch((err) => {
        console.error(err);
      });
    },
    tareScale() {
      const msg = JSON.stringify({ query: "tare", token: this.token });
      this.mqttPublish(`/${this.hsId}/query/request`, msg);
    },
    triggerHS(label){
      // Trigger HS to submit data
      // Receive submission ID from HS for tracking the submission
      if(this.zone && this.variant){
        const cfg = { 
          "farm": this.farm,
          "hsId": this.hsId,
          "zone": this.zone,
          "growarea": this.growarea,
          "variant": this.variant,
          "label": label,
          "massmod": this.harvestConfig['massmod']
        };
        if(this.batch){ cfg["batchId"] = this.batch._id; }
        const msg = JSON.stringify({query: "trigger", token: this.token, config: cfg, qty: this.qty});
        this.mqttPublish(`/${this.hsId}/query/request`, msg);
        this.$bvModal.hide("modal-harvest");
      }
    },
    openSubmissionModal() {
      if (this.zone && this.variant) {
        this.$bvModal.show("modal-harvest");
      } else {
        this.showErrorToast("No growarea or variant selected, please specify growarea and variant to trigger harvest station");
      }
    },
    getHarvestData(cId=-1){
      axios.get(`${this.farm}/harvests/data`, {
        params: {
          growarea: this.growarea,
          hsId: this.hsId,
          sessionId: this.token,
          batchId: this.batch?._id
        },
      })
      .then((response) => {
        this.rawHarvestData = response.data;
      
        if(cId >= 0){
          let sub = this.rawHarvestData.find((s) => {
            return s.capId == cId;
          });
          sub["status"] = "done";
          const idx = this.harvestData.findIndex((s) => {
            return s.capId == cId;
          });
          this.harvestData.splice(idx, 1);
          this.harvestData.push(sub);
        }
        else {
          this.rawHarvestData = this.rawHarvestData.map((h) => {
            h["status"] = "done";
            return h;
          });
          this.archivedHarvestData = this.rawHarvestData.filter((h) => { return h.expireAt != null })
          this.harvestData = this.rawHarvestData.filter((h) => { return h.expireAt == null })
        }
      })
      .catch((err) => {
        console.error(err);
      });
    },
    toggleScreen() {
      this.screensize = !this.screensize;
    },
    reset() {
      // Unsubscribe from data and close harvest station session
      if (this.token){
        const hs = this.hsId
        const msg = JSON.stringify({query: "endSession", user: this.user, token: this.token})
        this.mqttPublish(`/${hs}/query/request`, msg);
      }

      this.step = 0;
      this.screensize = 0;
      this.$emit('updatefarmSelect', false);
      this.$bvToast.hide("harvestWiz__error");

      //reset all data
      this.dataReady = false;
      this.token = null;
      this.resetHarvestConfig();
      this.totalAgg = { harvest: {qty: 0, mass: -1}, archived: {qty: 0, mass: -1} };
      this.harvestConfig = {
        tags: [],
        massmod: {name: "none", value: 0, label: "none (0g)"}
      }
      this.rawHarvestData = [];
      this.harvestData = [];
      this.archivedHarvestData = [];
      router.replace({name: "harvest"}, {params: {farm: this.farm}});
      clearTimeout(this.dataTimeout);
      this.getHarvestStations();
    },
    getBatch(bId){
      axios.get(`/${this.farm}/plantbatch/${bId}`, { })
      .then((response) => {
        this.batch = response.data;
        this.growarea = this.batch.growarea;
        this.variant = this.batch.variant;
        this.zone = this.batch.zone;
      })
      .catch((e) => {
        console.log(e);
        this.showErrorToast("error retrieving batch data");
        this.batch = null;
      });
    },
    resetHarvestConfig(){
      this.batch = null;
      this.hsId = null;
      this.zone = null;
      this.growarea = null;
      this.variant = null;
    },
    btnClickHandle(n) {
      if (!isNaN(n) || n === ".") {
        this.qty += n + "";
      }
      if (n == "DEL") {
        this.qty = this.qty.toString().slice(0, -1);
      }
      if (n == "RESET") {
        this.qty = 1;
      }
      if (
        this.qty.toString().charAt(0) == "0" &&
        this.qty.toString().length == 2
      ) {
        //handle 0 first in digit
        this.qty = n + "";
      }
    },
    showErrorToast(msg, autoHide=false){
			this.errMsg = msg;
      this.noAutoHide = autoHide;
			this.$bvToast.show('harvestWiz_error');
		},
		showSuccessToast(msg){
			this.sucMsg = msg;
			this.$bvToast.show('harvestWiz_success');
      this.getHarvestData(-1);
		},
  },
};
</script>

<style >
.harvest__card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 12px;
  height: 100%;
}
.harvest__header {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.harvest__header__btn * {
  margin: 5px;
}
.headerBtn {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;
  padding: 0;
}
.headerBtn * {
  margin: auto;
}
.harvest__body {
  padding: 10px 10px;
  flex-grow: 1;
}
.harveststation-list {
  display: flex;
  justify-content: space-between;
  text-align: center;
  list-style: none;
  flex-wrap: wrap;
}
.harveststation-card {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  height: 200px;
  width: 200px;
  box-sizing: border-box;
  background-color: rgb(190, 190, 190);
  color: white;
  transition: 0.3s cubic-bezier();
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.harveststation-card:hover {
  box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
  -moz-box-shadow: 2px 2px 5px 1px rgba(191, 190, 190, 1);
}
.harveststation-card * {
  margin: 10px 0px;
}
.harveststation-card__tags__icons {
  display: inline;
}
.harveststation-card__tags__icons * {
  margin: 0px 5px
}
.busy {
  border: 2px solid red;
}
.offline {
  border: 2px solid lightgrey;
}
.online {
  border: 2px solid rgb(0, 255, 4);
  background-color: white;
  color: grey;
}
.reserved {
  border: 2px solid yellow;
  background-color: white;
  color: grey;
}
.archived {
  background-color: lightgoldenrodyellow;
}
.harvest__card-tag__selected {
  background-color: var(--color-material-blue);
  color: white;
  padding: 2px;
  font-size: 15px;
  border-radius: 5px;
  margin: 0px;
}
.harvest__toast {
  position: fixed;
  z-index: 2;
  right: 10px;
  width: 400px;
}
.harvest__ops {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.massBox {
  border: 2px solid rgb(129, 129, 129);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.harvest__ops__ctrl {
  max-width: 300px;
  padding: 10px;
}
.harvest__ops__ctrl__btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.harvest__ops__ctrl__btn *{
  padding: 0px;
  margin: 0px 10px;
  width: 100%;
  height: 55px;
  font-size: 18px;
}
#modal-harvest {
  user-select: none;
}
.harvest__ops__details {
  margin: 20px;
}
.harvest__ops__details__info {
  list-style: none;
  padding: 0px;
  overflow-wrap: normal;
}
.harvest__ops__details__info li {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 10px;
  margin: 3px 10px;
  background-color: whitesmoke;
}
.harvest__ops__details__info .empty-selection {
  background-color: red;
}
.harvest__ops__details table {
  position: sticky;
  height: 75%;
  overflow-y: scroll;
}
.modal-harvest__info {
  border-radius: 5px;
  border: 1px solid black;
  font-family: 'courier new', 'monospace';
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.modal-harvest__info * {
  flex: 0 1 auto;
  width: 100%;
  border-radius: 5px;
  border: 1px solid darkgray;
  padding: 5px 10px;
  margin: 0px 0px;
}
.harvest__numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  height: 50vh;

  user-select: none;
  margin: 20px 10px;
}
.harvest__numpad div {
  border: 1px solid var(--color-material-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 5px;
}
.harvest__numpad__actions {
  grid-area: 5 / 2 / 6 / 3;
}
.trigger {
  height: 100%; 
  width: 100%; 
  font-size: 20px;
}
.pending {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px,
    #ff0 0 2px 12px;
}
.done {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}
.failed {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
}

@media only screen and (max-width: 426px) {
  /* For mobile phones: */
  .harvest__card {
    padding: 0;
  }
  .harvest__header h3{
    font-size: 1rem;
  }
  .harveststation-card {
    max-width: 100vw;
  }
  .harvest__body {
    padding: 0;
  }
  .harvest__toast {
    width: 300px;
  }
  .massBox {
    height: 160px;
  }
}
</style>
